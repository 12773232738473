import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';

import GlobalContext from '../../../context/global-context';
import { useLocaleFromRouter } from '../../../hooks/useLocaleFromRouter';
import { ReactComponent as WarningSVG } from '../../../images/warning_circle_red.svg';
import { NewPasswordInputState } from '../../../types/auth';
import { LocaleType } from '../../../utils/i18n';
import {
  EMAIL_VALIDATION,
  PASSWORD_ALL_REQUIREMENTS_VALIDATOR,
} from '../../../utils/regexes';
import './createAccount.scss';
import { pathToLogin, setUserSelectionData } from '../../../utils/user';
import Loading from '../../global/loading/loading';
import NewPasswordInput from '../newPasswordInput/newPasswordInput';

const CreateAccount: React.FC = () => {
  const { i18n, invitationToken, invitation } = useContext(GlobalContext);
  const currentLocale: LocaleType = useLocaleFromRouter();

  const [firstName, setFirstName] = useState({
    value: '',
    isValid: false,
    isTouched: false,
  });
  const [lastName, setLastName] = useState({
    value: '',
    isValid: false,
    isTouched: false,
  });
  const [email, setEmail] = useState({
    value: invitation ? invitation.email : '',
    isValid: invitation ? true : false,
    isTouched: false,
  });
  const [newPassword, setNewPassword] = useState<NewPasswordInputState>({
    value: '',
    isValid: false,
  });
  const [error, setError] = useState(false);
  const [registerError, setRegisterError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successConfirmed, setSuccessConfirmed] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (newPassword)
      setNewPassword((prevValue) => {
        return {
          ...prevValue,
          isValid: PASSWORD_ALL_REQUIREMENTS_VALIDATOR.test(newPassword.value),
        };
      });
  }, [newPassword.value]);

  const resetAllError = () => {
    setError(false);
    setRegisterError(false);
  };

  const handleChange = (value: string, setFn: any, field: string) => {
    let isValid = false;
    if (field === 'firstName' || field === 'lastName') {
      value = value.trim();
      isValid = value.length > 0 ? true : false;
    }
    if (field === 'email') isValid = EMAIL_VALIDATION.test(value);
    setFn({ value, isValid });
  };

  const handleBlur = (setFn: any) => {
    setFn((prevValue: any) => {
      return { ...prevValue, isTouched: true };
    });
  };

  const checkValidityOnSubmit = (): boolean => {
    return (
      firstName.isValid &&
      lastName.isValid &&
      email.isValid &&
      newPassword.isValid
    );
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    // Already submitting
    if (submitting) {
      return;
    }

    resetAllError();

    if (!checkValidityOnSubmit()) return setError(true);
    if (checkValidityOnSubmit()) {
      try {
        setSubmitting(true);

        axios
          .post(`${process.env.GATSBY_STRAPI_URL}/api/auth/local/register`, {
            email: email.value,
            firstName: firstName.value,
            lastName: lastName.value,
            password: newPassword.value,
            locale: currentLocale,
            invitationToken,
          })
          .then((response) => {
            if (response.status === 200) {
              resetAllError();
              setSuccess(true);
              setSuccessConfirmed(response.data.confirmed);
              setUserSelectionData(response.data.userSelection);
            }
          })
          .catch((err) => {
            resetAllError();
            setRegisterError(true);
          })
          .finally(() => {
            setSubmitting(false);
          });
      } catch (err) {
        resetAllError();
        setRegisterError(true);
        setSubmitting(false);
      }
    }
  };

  let termsSlug = i18n?.t('terms_conditions.slug');
  termsSlug = termsSlug ? termsSlug : 'terms-conditions';

  return (
    <div className="auth-content-wrapper">
      <div className="auth-form-wrapper create-account">
        {success && !successConfirmed && (
          <>
            <h2 className="auth-title">{i18n?.t('check_your_email')}</h2>
            <p>
              {i18n
                ?.t('activate_account_email_sent')
                .replace('[email]', email.value)}
            </p>
            <p>{i18n?.t('check_your_email_spam')}</p>
          </>
        )}
        {success && successConfirmed && (
          <>
            <h2 className="auth-title">{i18n?.t('account_created')}</h2>
            <p>{i18n?.t('login_access_business')}</p>
            <a
              className="auth-link"
              href={pathToLogin(currentLocale, i18n, invitationToken)}
            >
              {i18n?.t('login')}
            </a>
          </>
        )}
        {!success && (
          <>
            <h2 className="auth-title">{i18n?.t('create_your_account')}</h2>
            <span className="auth-subtitle">
              {i18n?.t('already_have_an_account')}
            </span>{' '}
            <a
              className="auth-link"
              href={pathToLogin(currentLocale, i18n, invitationToken)}
            >
              {i18n?.t('login')}
            </a>
            <form className="create-account-form" onSubmit={handleSubmit}>
              <div className="name-section">
                <div className="name-input">
                  <label>{i18n?.t('form.labels.firstname')}</label>
                  <input
                    type="text"
                    onChange={(e) => {
                      handleChange(e.target.value, setFirstName, 'firstName');
                    }}
                    onBlur={() => {
                      handleBlur(setFirstName);
                    }}
                    className={
                      !firstName.isValid && firstName.isTouched
                        ? 'red-border'
                        : ''
                    }
                  ></input>
                  {!firstName.isValid && firstName.isTouched && (
                    <span className="warning">
                      {i18n?.t('form.errors.firstname')}
                    </span>
                  )}
                </div>

                <div className="name-input">
                  <label>{i18n?.t('form.labels.lastname')}</label>
                  <input
                    type="text"
                    onChange={(e) => {
                      handleChange(e.target.value, setLastName, 'lastName');
                    }}
                    onBlur={() => {
                      handleBlur(setLastName);
                    }}
                    className={
                      !lastName.isValid && lastName.isTouched
                        ? 'red-border'
                        : ''
                    }
                  ></input>
                  {!lastName.isValid && lastName.isTouched && (
                    <span className="warning">
                      {i18n?.t('form.errors.lastname')}
                    </span>
                  )}
                </div>
              </div>
              <div>
                <label>{i18n?.t('form.labels.email2')}</label>
                <input
                  type="text"
                  onChange={(e) => {
                    handleChange(e.target.value, setEmail, 'email');
                  }}
                  onBlur={() => {
                    handleBlur(setEmail);
                  }}
                  className={
                    !email.isValid && email.isTouched ? 'red-border' : ''
                  }
                  defaultValue={email.value}
                  disabled={invitation !== undefined}
                ></input>
                {!email.isValid && email.isTouched && (
                  <span className="warning">
                    {i18n?.t('form.errors.invalid_email2')}
                  </span>
                )}
              </div>
              <NewPasswordInput
                newPasswordState={newPassword}
                setNewPasswordState={setNewPassword}
                label={i18n?.t('form.labels.password') ?? 'Password'}
                type="new"
              />

              <span className="terms-and-conditions">
                {i18n?.t('create_account_agree_terms1')}{' '}
                <a
                  href={`/${currentLocale}/${termsSlug}`}
                  target="_blank"
                  className="terms-link"
                >
                  {i18n?.t('create_account_agree_terms.terms.link.text')}
                </a>
                {i18n?.t('create_account_agree_terms2')}{' '}
                <a className="terms-link">
                  {i18n?.t(
                    'create_account_agree_terms.privacy_policy.link.text'
                  )}
                </a>
                .
              </span>
              <button className="submit-button" type="submit">
                {submitting && <Loading type="btn" />}
                {!submitting && i18n?.t('create_account')}
              </button>
            </form>
            {error && (
              <div className="error-container">
                <div className="icon-container">
                  <WarningSVG />
                </div>
                <p>{i18n?.t('form.errors.all_fields')}</p>
              </div>
            )}
            {registerError && (
              <div className="error-container">
                <div className="icon-container">
                  <WarningSVG />
                </div>
                <p>{i18n?.t('form.errors.account_creation')}</p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CreateAccount;
