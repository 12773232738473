import React from 'react';

import CreateAccount from '../../components/auth/createAccount/createAccount';
import AuthLayout from '../../components/global/auth/authLayout/authLayout';
import { AuthPageBase } from '../../context/global-context';
import { AuthPageData, AuthPageType } from '../../types/auth';
import { LocalizationData } from '../../types/general';
import { PageContext } from '../../types/page';
import { LocaleEnum, getLocales, useI18n } from '../../utils/i18n';

type AuthCreateContext = PageContext & {
  locale: LocaleEnum;
  slug: string;
};

const CreateAccountPage: React.FC<{
  pageContext: AuthCreateContext;
}> = ({ pageContext }) => {
  const { translations, locale, slug } = pageContext;

  const i18n = useI18n(translations);
  const locales = getLocales([locale]);
  const localizationsData: LocalizationData[] = [];

  locales.forEach((l) => {
    const slug = i18n.t('register.slug', l);

    localizationsData.push({
      attributes: {
        slug: slug ? slug : 'register',
        locale: l,
      },
    });
  });

  const instance: AuthPageData = {
    authPageType: AuthPageType.createAccount,
    attributes: {
      slug: slug,
      locale: locale,
      localizations: {
        data: localizationsData,
      },
    },
  };

  return (
    <AuthPageBase translations={translations} instance={instance}>
      <AuthLayout>
        <CreateAccount />
      </AuthLayout>
    </AuthPageBase>
  );
};

export default CreateAccountPage;
